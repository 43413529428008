<template lang="pug">
BasePane
  Accordion(fix)
    template(#title)
      span {{ $t('sizeAndSpacing') }}
    OmColorInput(
      :label="$t('color')"
      property="desktop.border.color"
      typeOfComponent="divider"
      layout="col"
      dsAllowed
    )
    RangeInput(:label="$t('width')" v-model="width" :min="10" :max="800" :step="1")
    RangeInput(:label="$t('height')" v-model="borderWidth" :min="1" :max="40" :step="1")
    Dropdown#dividerType(
      :label="$t('type')"
      layout="col"
      :items="borderTypeItems"
      v-model="borderType"
    )
    Align(:label-override="$t('align')" v-model="alignSelf" editMobile flexOptions)
  hr
  template(#advancedContent)
    Margin
    Shadow(v-model="shadow")
    Hide(:hideOnDesktop.sync="hideElementOnDesktop" :hideOnMobile.sync="hideElementOnMobile")
</template>
<script>
  import itemMixin from '@/editor/mixins/item';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import BasePane from '@/editor/components/sidebar/panes/level2/BasePane.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import Align from '@/components/Editor/Controls/Alignments/Align.vue';
  import Margin from '@/components/Editor/Controls/InputGroup/Margin.vue';
  import Shadow from '@/components/Editor/Controls/Shadow.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';

  export default {
    components: {
      BasePane,
      Accordion,
      RangeInput,
      Dropdown,
      Align,
      Margin,
      Shadow,
      Hide,
    },
    mixins: [itemMixin],
    data() {
      return {
        canEditMobile: true,
      };
    },
    computed: {
      borderTypeItems() {
        return [
          { value: 'solid', text: this.$t('line') },
          { value: 'dashed', text: this.$t('dashed') },
          { value: 'dotted', text: this.$t('dotted') },
        ];
      },
      borderType: {
        get() {
          return this.getValueOf('border.type');
        },
        set(v) {
          this.setValueOf('border.type', v);
        },
      },
      width: {
        get() {
          return Number(this.getValueOf('desktop.width'));
        },
        set(v) {
          this.setValueOf('desktop.width', v);
        },
      },
      borderWidth: {
        get() {
          return Number(this.getValueOf('desktop.border.width'));
        },
        set(v) {
          this.setValueOf('desktop.border.width', v);
        },
      },
      alignSelf: {
        get() {
          return this.getValueOf(`$device.alignSelf`);
        },
        set(v) {
          this.smartSetValueOf(`$device.alignSelf`, v);
        },
      },
      hideElementOnDesktop: {
        get() {
          return this.getValueOf('desktop.hidden');
        },
        set(v) {
          this.setValueOf('desktop.hidden', v);
        },
      },
      hideElementOnMobile: {
        get() {
          return this.smartGetValueOf('mobile.hidden');
        },
        set(v) {
          this.smartSetValueOf('mobile.hidden', v);
        },
      },
      shadow: {
        get() {
          return this.getValueOf('shadow.type');
        },
        set(value) {
          this.setValueOf('shadow.type', value);
        },
      },
    },
  };
</script>
